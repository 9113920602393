.trip-detail-container {
  padding: 15px 25px !important;
}

.username {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}

.user-info {
  background-color: white;
  padding: 24px;
  margin-top: 20px;
  border-radius: 10px;

  &>div:first-child {
    font-weight: 600;
  }

  &>div:last-child {
    margin-left: 20px;
  }
}

.trip-info {
  margin-top: 40px;

  &>div>div:first-child {
    font-weight: 500;
  }
}

.btn-condition-report {
  background-color: black;
  border-radius: 10px;
  padding: 25px 15px;
  margin-top: 40px;
  align-items: center;
  cursor: pointer;

  &>div {
    color: white;
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
  }
}

.image-car {
  border-radius: 8px;
  object-fit: cover;
}

.btn-incident-report {
  background-color: #F04343;
  border-radius: 10px;
  padding: 15px;
  margin: 20px 0;
  align-items: center;
  cursor: pointer;
}

.incident-title-btn {
  color: white;
  margin-left: 10px;
  font-weight: bold;
}

.incident-info {
  margin-left: 27px;
  color: white;
  font-size: 13px;
  margin-top: 3px;
}

.trip-label {
  font-size: 18px;
  font-weight: bold;
}

.report-scroll {
  max-height: 70vh;
  overflow: auto;
}