.stick-header {
  top: -100px;
  transition: top 0.5s ease-in-out;
}

.sticky {
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  animation-duration: 0.3s;
}

.bg-header {
  background: #fff !important;
  @media (max-width: 991.98px) {
    background: #5025ab !important;
  }
}

.menu-background {
  @media (min-width: 991.98px) {
    margin: 0 !important;
  }
}

@keyframes dropdown-menu {
  from {
    top: -15px;
    transform: translateY(0);
  }
  to { 
    top: 0;
    transform: translateY(0);
  }
}

.padding-header-desktop {
  padding: 0 40px !important;
}

.resize-header {
  height: 65px !important;
}