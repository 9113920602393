.appear {
  transition: 0.35s ease;
  max-height: 100%;
  opacity: 1;
}

.disappear {
  transition: 0.1s ease;
  max-height: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
}

.collapse-icon {
  transition: all 0.2s ease;
  transform: rotate(0);
}

.open-icon {
  display: flex;
  flex-shrink: 0;
  transition: all 0.3s ease;
  transform: rotate(90deg);
  align-items: center;
  justify-content: center;

  svg path {
    fill: #ad0873 !important;
  }
}

.add-session-icon {
  display: flex;
  flex-shrink: 0;
  transition: all 0.2s ease;
  transform: rotate(90deg);
  align-items: center;
  justify-content: center;

  svg path {
    transition: fill 0.3s ease !important;
    fill: #ad0873 !important;
    height: 1.25 rem !important;
    width: 1.25 rem !important;
  }
}

.add-session-txt {
  margin: 0;
  color: #FF9D00;
  font-weight: 500;
}