::-webkit-file-upload-button {
    display: none;
 }

 ::-webkit-file-txt {
    display: none;
 }

 ::file-selector-button {
    display: none;
  }