.position-select-list {
  position: absolute;
  width: 100%;
  left: 0;
  top: 42px;
  background-color: #ffffff;
}

.select-input {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.select-input--multiple {
  height: auto;
  flex-wrap: wrap;
}

.select-click {
  flex: 1;
  height: 21px;
}

.select-input::before {
  position: absolute;
  content: '';
  right: 5px;
  top: 10px;
  border-top: 5px solid #252525;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.select-title {
  color: #252525;
}

.select-title.placeholder {
  color: #ccc;
}

.select-list {
  border: 0;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  border-radius: 0.65rem;
  padding: 1rem 0;
  z-index: 2;
}

.select-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-weight: normal;
}

.select-item:hover {
  background: #ff9d001a;

  span {
    color: #FF9D00;
  }
}

.select-header-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-weight: normal;
  background-color: #FF9D00;
  color: #fff;
}

.selected-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  span {
    color: '#5e6278';
    font-size: 14px;
    font-weight: normal;
  }
}

.selected-item {
  border-radius: 0.4rem;
  padding: 0 0.6rem;
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: #e4e6ef;
  font-size: 12px;
  font-weight: normal;
  margin-right: 5px;
}

.selected-item span {
  margin-right: 5px;
}

.search-wrap {
  padding: 0.5rem 1.25rem;
  margin: 0 0 0.5rem 0;
}

.select-search {
  width: 100%;
  padding: 0.55rem 0.75rem;
  color: #181c32;
  font-size: .925rem;
  border: 1px solid #e4e6ef;
  border-radius: 0.65rem;
  outline: 0 !important;
}

.sticky-search {
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 7px;
}