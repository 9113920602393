.profile-container {
  width: 90%;
  max-width: 400px;
  position: absolute;
  z-index: 999;
  right: 0;
  height: 100vh;

  .bg-profile-info {
    background: #6B4EB4;
    color: #fff;
  }

  .header-profile {
    height: auto;
  }
}

@media screen and (max-width: 380px), 
       screen and (max-height: 497) {
  .media-special-device {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}