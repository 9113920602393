@import "./components/mixins";
@import "./components/variables";

a {
  color: $info;
  font-weight: 500;

  &:hover,
  &:focus {
    color: #FF9E00;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg {
  &-primary {
    background: $btn-primary-color  !important;
  }

  &-light-primary-2 {
    background: #F4E4EE;
  }

  &-info {
    background: $btn-info-color  !important;
  }

  &-info-op9 {
    background: transparentize($bg-info-color, 0.1);
    color: $white;
  }

  &-warning {
    &-dark-op8 {
      background: rgba($warning-dark, 0.2);
    }
  }

  &-lg-transparent {
    @media (min-width: 992px) {
      background: transparent !important;

      .header-fixed[data-kt-sticky-header="on"] &.header {
        background: #fff !important;
      }
    }
  }
}

.border-info-3px {
  border: 3px solid $info;
}

.underline {
  text-decoration: underline;
}

.form-switch {
  label::after {
    content: attr(data-off);
    display: inline-block;
  }

  input:checked+label::after {
    content: attr(data-on);
  }
}

@media (max-width: 1199px) {
  [data-kt-aside-minimize="on"] .chart-engagement {
    flex-direction: row !important;
  }
}

.ellipsis-2rows {
  @include ellipsisText(2);
}

.ellipsis-1row {
  @include ellipsisText(1);
}

select {
  background: transparent;
  border: 0;
  border-bottom: 1px solid $gray;
  -webkit-appearance: none;
  border-radius: 0;
}

.image-input {

  [data-kt-image-input-action="change"],
  [data-kt-image-input-action="cancel"],
  [data-kt-image-input-action="remove"] {
    top: auto;
    bottom: 0;
    right: 0;
  }
}

.border {
  &-start {
    border-left: 1px solid $border-color  !important;
    border-radius: 0;
  }
}

.block_activities-list {
  .title {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.activity-search-result {
  &__link-to-library {
    top: 0;
    left: 0;
    right: 0;
  }

  &__wrapper {
    left: 0;
    right: 0;
  }
}

.form-control.tagify {
  white-space: nowrap;
  overflow: auto;
}

textarea {
  resize: none;
}

.activity__figure {
  padding-top: 200px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.form-check.form-check-info .form-check-input:checked {
  background-color: $info;
}

.client-state {
  border-left: 10px solid;

  svg {
    width: 57px;
    height: 57px;
  }

  &--yellow {
    @include clientState($yellow, 48%);
  }

  &--pink {
    @include clientState($pink, 25%);
  }

  &--purple-light {
    @include clientState($info-light, 20%);
  }
}

footer .menu-link {

  &,
  i {
    color: #e9e1ee;
  }
}

#kt_mega_menu_toggle {
  background: rgba($white, 0.05);

  &:hover {
    background: $info;
  }
}

.ribbon.ribbon-start .ribbon-label.ribbon-topic {
  top: 80%;
}

#modal-between-chapters .modal-content {
  background-repeat: no-repeat;
  background-size: 140%;
  background-position: -40px -160px;
}

.stepper.stepper-pills .stepper-item {
  .stepper-icon {
    background: #dc94c3;
  }
}

.stepper.stepper-pills.stepper-column .stepper-line {
  border-left-color: #DC94C3;
}

.aside-primary.bg-info {
  background: #FF9D00;
}

.btn.btn-info {
  background: $info;
  border-color: $info;
  cursor: pointer;
}

.btn.btn-primary {
  background: $info;
  border-color: $info;
}

// bg for page login
#kt_login {
  background: $warning-light-2;

  .form-control-solid {
    background: $white;
  }
}

// normal/hover state for icon Profile on Header
.btn-icon-user {
  i {
    color: $black-4A;
  }

  &:hover {
    background: $accent;
  }

  @media (max-width: 991.98px) {
    .bg-info & {
      i {
        color: $white;
      }

      &:hover i {
        color: $black-4A;
      }
    }
  }
}

// redefined btn & bg color (&-info, &-primary)
.btn-primary,
.btn-check:checked+.btn.btn-active-primary,
.btn-check:active+.btn.btn-active-primary,
.btn.btn-active-primary:focus:not(.btn-active),
.btn.btn-active-primary:hover:not(.btn-active),
.btn.btn-active-primary:active:not(.btn-active),
.btn.btn-active-primary.active,
.btn.btn-active-primary.show,
.show>.btn.btn-active-primary {
  background: $btn-primary-color;
  border-color: $btn-primary-color;
}

.btn-info,
.btn-check:checked+.btn.btn-active-info,
.btn-check:active+.btn.btn-active-info,
.btn.btn-active-info:focus:not(.btn-active),
.btn.btn-active-info:hover:not(.btn-active),
.btn.btn-active-info:active:not(.btn-active),
.btn.btn-active-info.active,
.btn.btn-active-info.show,
.show>.btn.btn-active-info {
  background: $btn-info-color;
  border-color: $btn-info-color;
}

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  color: $info;
  background-color: lighten($info, 50%);
}

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected {
  background: $info;
  color: $white;
}

.btn-view-mode {

  &:hover,
  &.active {
    background: $accent;
  }
}

@media (min-width: 1200px) {
  .text-xl-dark {
    color: #181c32 !important;
  }
}

@media (min-width: 992px) {
  .h-lg-120px {
    height: 120px !important;
  }
}

@media (min-width: 768px) {
  .flex-column-md-fluid {
    flex: 1 0 auto;
  }
}

@media (max-width: 360px) {
  footer {
    font-size: 0.875rem;
  }
}


.aside-secondary .hover-scroll-y {
  overflow-y: scroll;
}

.user-avatar {
  object-fit: cover;
  border-radius: 999px;
}