
@mixin ellipsisText($row: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  /*! autoprefixer: ignore next */
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-line-clamp: $row;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

@mixin clientState($color, $amount) {
  border-color: $color;
  svg [fill] {
    stroke: $color;
    &:not(.permanent):not(g) {
      fill: transparent;
    }
  }
  svg > g > ellipse {
    fill: $color!important;
  }

  &:hover {
    background: lighten($color, $amount);
  }
}