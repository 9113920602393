.mobile-table {
  overflow: hidden;
  position: relative;
  border: 0px;
  width: 100%;
  overflow-x: auto;
  box-sizing: content-box;
  padding-right: 0px;
}

.single-select .selected-list span {
  color: #5e6278;
  font-size: 0.925rem !important;
}

.kt-back-mobile {
  z-index: 95;
  position: fixed;
  top: 55px;
  width: 100%;
}
