.activity-summary-height {
  min-height: 50px;
}

.reg-text {
  font-size: 19px;
}

.car-id {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 60px;
  height: 40px;
  background-color: #00000047;
  color: white;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
}