$info: black;
$info-dark: black;
$info-light: #BBA9DE;
$warning-light: #FFF4DE;
$warning-light-2: #FAF8F6;
$warning-dark: #B1A45E;
$white: #fff;
$gray: #e9edf1;
$yellow: #FFC700;
$pink: #DC94C3;

$accent: #F9F2E7;

$border-color: #eff2f5;

$black-4A: #4A4A4A;

$btn-info-color: $info-dark;
$bg-info-color: $info;

$btn-primary-color: #B4015B;
$bg-primary-color: #AD0873;