.end-tag-trip {
  background-color: #FCA311;
}

.incident-report-trip {
  background-color: #F04343;
}

.complete-trip {
  background-color: #45DD6F;
}

.end-tag-trip,
.incident-report-trip,
.complete-trip {
  color: white;
  font-size: 12px;
  padding: 3px 9px;
  border-radius: 12px;

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  &>div:first-child {
    margin-right: 5px;
  }
}

.car-code-trip {
  font-size: 13px;
}

.car-trip {
  font-weight: bold;
  font-size: 14px;
}

.vehicle-image {
  object-fit: contain;
  border-radius: 8px;
}

.link {
  color: #28A5FF;
}