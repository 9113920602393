.trip-info-log {
  width: 100%;
  margin-top: 40px;

  &>div>div:first-child {
    font-weight: 500;
  }
}

.image-map {
  object-fit: cover;
  border-radius: 5px;
  margin-top: 30px;
}

.modal-title-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;

  &>div:first-child {
    font-size: 18px;
    font-weight: bold;
  }
}

.media {
  border-radius: 15px;
  margin-top: 10px;
}

.report-title {
  font-weight: bold;
  margin-top: 30px;

  &:not(:first-of-type) {
    margin-top: 80px;
  }
}

.report-description-title {
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.report-description {
  font-size: 12px;
  margin-top: 5px;
}